import Footer from "./Footer";
import NavBar from "./NavBar";
import "./styles/aboutus.css";
import "./styles/resources.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="resources">
        <div className="container">
          <NavBar />

          <div className="title_faq">
            <h1>Privacy Policy.</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="aboutus mt3 mb2">
          <div className="abtUsAns">
            At docity.co, accessible from qa.docity.co, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by docity.co and how we use it.
            <br />
            <br />
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
            <br />
            <br />
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in docity.co. This policy is not
            applicable to any information collected offline or via channels
            other than this website. Our Privacy Policy was created with the
            help of the Online Generator of Privacy Policy.
          </div>
          <br />
          <br />
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">Consent </h3>
            {/* <button className="verifyProperty">Verify Property Now</button> */}
          </div>
          <div className="abtUsAns">
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms
          </div>
        </div>
        <div className="aboutus mt3 mb2">
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">Information we collect </h3>
            {/* <button className="verifyProperty">Verify Property Now</button> */}
          </div>
          <div className="abtUsAns">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
            <br />
            <br />
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
            <br />
            <br />
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </div>
        </div>
        <div className="aboutus mt3 mb2">
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">How we use your information </h3>
          </div>
          <div className="abtUsAns">
            We use the information we collect in various ways, including to:
            <br />
            <br />
            <ul>
              <li>1. Provide, operate, and maintain our website</li>
              <li>2. Improve, personalize, and expand our website</li>
              <li>3. Understand and analyze how you use our website</li>
              <li>
                4. Develop new products, services, features, and functionality
              </li>
              <li>
                5. Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>6. Send you emails</li>
              <li>7. Find and prevent fraud</li>
            </ul>
          </div>
        </div>
        <div className="aboutus mt3 mb2">
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">Log Files </h3>
            {/* <button className="verifyProperty">Verify Property Now</button> */}
          </div>
          <div className="abtUsAns">
            docity.co follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services' analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information.
          </div>
        </div>
        <div className="aboutus mt3 mb4">
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">
              Advertising Partners Privacy Policies{" "}
            </h3>
            {/* <button className="verifyProperty">Verify Property Now</button> */}
          </div>
          <div className="abtUsAns">
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of docity.co.
            <br />
            <br />
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on docity.co, which
            are sent directly to users' browser. They automatically receive your
            IP address when this occurs. These technologies are used to measure
            the effectiveness of their advertising campaigns and/or to
            personalize the advertising content that you see on websites that
            you visit.
            <br />
            <br />
            Note that docity.co has no access to or control over these cookies
            that are used by third-party advertisers.
          </div>
        </div>
        <div className="aboutus mt3 mb4">
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">Third Party Privacy Policies</h3>
            {/* <button className="verifyProperty">Verify Property Now</button> */}
          </div>
          <div className="abtUsAns">
            docity.co's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.
            <br />
            <br />
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </div>
        </div>
        <div className="aboutus mt3 mb4">
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </h3>
            {/* <button className="verifyProperty">Verify Property Now</button> */}
          </div>
          <div className="abtUsAns">
            Under the CCPA, among other rights, California consumers have the
            right to:
            <br />
            <br />
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
            <br />
            <br />
            Request that a business delete any personal data about the consumer
            that a business has collected.
            <br />
            <br />
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
            <br />
            <br />
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </div>
        </div>
        <div className="aboutus mt3 mb4">
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">GDPR Data Protection Rights</h3>
            {/* <button className="verifyProperty">Verify Property Now</button> */}
          </div>
          <div className="abtUsAns">
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
            <br />
            <br />
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
            <br />
            <br />
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete
            <br />
            <br />
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
            <br />
            <br />
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions
            <br />
            <br />
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
            <br />
            <br />
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
            <br />
            <br />
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us
          </div>
        </div>
        <div className="aboutus mt3 mb4">
          <div className="d-flex space-between mb1">
            <h3 className="primary-text-color">Children's Information</h3>
            {/* <button className="verifyProperty">Verify Property Now</button> */}
          </div>
          <div className="abtUsAns">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
            <br />
            <br />
            docity.co does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
