import {
  ContactMeActionTypes,
  DeleteDocActionTypes,
  FetchPropertiesActionTypes,
  FetchPropertyActionTypes,
  UploadDocActionTypes,
  UploadTypesActionTypes,
  VerifyPropertyActionTypes,
  DocWriterActionTypes,
  AddNoteToDocActionTypes,
  AddAnnotationActionTypes,
  UpdateAnnotationActionTypes,
  FinalReportActionTypes,
  ShareReportActionTypes,
} from "./ActionTypes";

export const actionVerifyProperty = (payload) => ({
  type: VerifyPropertyActionTypes.REQUEST,
  payload,
});

export const verifyPropertyReset = () => ({
  type: VerifyPropertyActionTypes.RESET,
});

export const actionUpdateVerifyProperty = (payload) => ({
  type: VerifyPropertyActionTypes.CHANGED,
  payload,
});

export const actionContactMe = (payload) => ({
  type: ContactMeActionTypes.REQUEST,
  payload,
});

export const actionGetUploadTypes = (payload) => ({
  type: UploadTypesActionTypes.REQUEST,
  payload,
});

export const actionFetchProperties = (filters) => ({
  type: FetchPropertiesActionTypes.REQUEST,
  filters,
});

export const actionFetchProperty = (payload) => ({
  type: FetchPropertyActionTypes.REQUEST,
  payload,
});

export const resetProperty = () => ({
  type: FetchPropertyActionTypes.RESET,
});

export const resetProperties = () => ({
  type: FetchPropertiesActionTypes.RESET,
});

export const actionUploadDoc = (payload) => ({
  type: UploadDocActionTypes.REQUEST,
  payload,
});

export const deleteDoc = (payload) => ({
  type: DeleteDocActionTypes.REQUEST,
  payload,
});

export const addNoteToDoc = (payload) => ({
  type: AddNoteToDocActionTypes.REQUEST,
  payload,
});

export const docWriter = (payload) => ({
  type: DocWriterActionTypes.REQUEST,
  payload,
});

export const resetDocWriter = () => ({
  type: DocWriterActionTypes.RESET,
});

export const actionAddAnnotation = (payload) => ({
  type: AddAnnotationActionTypes.REQUEST,
  payload,
});

export const actionUpdateAnnotation = (payload) => ({
  type: UpdateAnnotationActionTypes.REQUEST,
  payload,
});

export const actionFinalReport = (payload) => ({
  type: FinalReportActionTypes.REQUEST,
  payload,
});

export const shareReportRequest = (payload) => ({
  type: ShareReportActionTypes.REQUEST,
  payload,
});

export const shareReportReset = () => ({
  type: ShareReportActionTypes.RESET,
});
