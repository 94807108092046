import MapComponent from "./AddressFillingComponents/MapComponent";
import WhiteNavBar from "./WhiteNavBar";
import "./styles/request.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  actionFetchProperties,
  resetProperty,
  resetProperties,
} from "../modules/property-contact";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getUser } from "../utils/utils";
import { ROLE_CE, ROLE_SUPER_ADMIN, STATUS_LIST } from "../constants";
import { fetchCE } from "../modules/civil-engg/Actions";
import InviteCE from "./InviteCE";
import Alert from "./AlertComponent";
import { Spinner } from "./FinalReportGeneration";
import UserIcon from "../assets/images/user.svg";
import Arrow from "../assets/images/backarrow.png";
import SuccessAndCompleted from "../assets/images/success&completed.png";
import Submit from "../assets/images/submit.png";
// import Pending from "../assets/images/pending.png";
import Inprogress from "../assets/images/inprogress.png";
import Draft from "../assets/images/draft.png";
import Assigned from "../assets/images/assigned.png";
import moment from "moment";

const Users = (props) => {
  console.log("civil engineers props: ", props);
  const history = useHistory();
  const location = useLocation();
  const [showCE, setShowCE] = useState(true);
  const [showInvite, setShowInvite] = useState(false);
  const [errImg, setErrImg] = useState({});
  const [filters, setFilters] = useState({
    page: 0,
    limit: 12,
  });
  const loaderRef = useRef();
  useEffect(() => {
   
      props.fetchCE();
    

    const observer = new IntersectionObserver(
      (entities) => {
        let target = entities[0];
        if (target.isIntersecting) {
          setFilters((filters) => {
            return {
              ...filters,
              page: filters.page + 12,
            };
          });
        }
      },
      {
        root: null,
        rootMargin: "100px",
        threshold: 0,
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      props.resetProperties();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.hasMoreResults) {
      props.fetchCE(filters);
    }
    //eslint-disable-next-line
  }, [filters.page]);

 
  return (
    <div className="requests">
      <WhiteNavBar showCE={showCE} />
      <div className="container mt7">
        <div className="flex-center space-between">
          <div className="d-flex">
            {<img
              src={Arrow}
              alt=""
              className="cursor icon-back"
              onClick={() => {
                history.goBack();
              }}
            /> }
           
            {getUser()?.role?.[0]?.name === ROLE_SUPER_ADMIN && showCE && (
              <h4
                className="text-dark-gray cursor"
                onClick={() => {
                  setShowCE(true);
                }}
              >
                Civil Engineer Profiles
              </h4>
            )}
          </div>
          {getUser()?.role?.[0]?.name !== ROLE_SUPER_ADMIN &&
            getUser()?.role?.[0]?.name !== ROLE_CE && (
              <button
                className="primary-color"
                onClick={() => {
                  props.resetProperty();
                  history.push("/steps/addressFilling");
                }}
              >
                Verify new property
              </button>
            )}
        </div>
        <Alert />

        {showCE ? (
          <>
            <div className="mt3 request-grid">
              <InviteCE open={showInvite} setOpen={setShowInvite} />
              {getUser()?.role?.[0]?.name === ROLE_SUPER_ADMIN && (
                <button
                  className="inviteCE primary-color m1"
                  onClick={() => {
                    setShowInvite(true);
                  }}
                >
                  Invite Civil Engineer
                </button>
              )}
              {props?.civilEnggs && props?.civilEnggs?.length > 0 ? (
                props?.civilEnggs?.map((item, i) => (
                  <div className="elv-card req-item" key={i}>
                    <div className="flex-center ce-bg">
                      <div className="ce-img">
                        <img
                          src={
                            errImg[item.profileImage] || !item.profileImage
                              ? UserIcon
                              : item.profileImage
                          }
                          alt="user"
                          onError={() => {
                            setErrImg((errImgs) => ({
                              ...errImgs,
                              [item.profileImage]: true,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className="m1">
                      <div>
                        <span className="ce-left">Name:</span>
                        <span className="ce-right">{item.firstName}</span>
                      </div>
                      <div>
                        <span className="ce-left">KYC:</span>
                        <span className="ce-right">
                          {STATUS_LIST?.[item?.kycVerified]?.name}
                        </span>
                      </div>
                      <div>
                        <span className="ce-left">Online training:</span>
                        <span className="ce-right">
                          {STATUS_LIST?.[item?.trainingStatus]?.name}
                        </span>
                      </div>
                      <div>
                        <span className="ce-left">Assessment:</span>
                        <span className="ce-right">
                          {STATUS_LIST?.[item?.assessmentStatus]?.name}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  {props?.properties?.length === 0 && (
                    <div className="no-request">
                      <h2>No Data Found</h2>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div ref={loaderRef} className="mt-1 mb-1">
              {props.Ploading && (
                <div className="flex-center relative">
                  <div className="">
                    <Spinner />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div>
          
                <div className="no-request">
                  <h2>No Data Found.</h2>
                </div>
              
            </div>

            <div ref={loaderRef} className="mt-1 mb-1">
              {props.Ploading && (
                <div className="flex-center relative">
                  <div className="pSticky">
                    <Spinner />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    properties: state?.propertyContact?.properties?.data?.list,
    Ploading: state.propertyContact?.properties.loading,
    civilEnggs: state?.civilEng?.CEData?.list,
    hasMoreResults: state?.propertyContact?.properties?.data?.hasMoreResults,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionFetchProperties,
      fetchCE,
      resetProperty,
      resetProperties,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
